var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('data-table',{attrs:{"headers":_vm.headers,"hide-default-footer":"","show-expand":"","single-expand":"","server-pagination":"","search":_vm.search,"endpoint":"/Issue/GetCurrentGeneralIssues","mappingFunction":_vm.mappingFunction},scopedSlots:_vm._u([{key:"item.orderingCompanyUserId",fn:function(ref){
var item = ref.item;
return [(item.orderingCompanyUser)?_c('div',[_vm._v(" "+_vm._s(item.orderingCompanyUser.name)+" "+_vm._s(item.orderingCompanyUser.surname)+" ")]):_vm._e()]}},{key:"item.linkedCompanyUserId",fn:function(ref){
var item = ref.item;
return [(item.linkedCompanyUser)?_c('div',[_vm._v(" "+_vm._s(item.linkedCompanyUser.name)+" "+_vm._s(item.linkedCompanyUser.surname)+" ")]):_c('div',[_vm._v(" "+_vm._s(_vm.$t('issues.notApplicable'))+" ")])]}},{key:"item.linkedCompanyDisplayId",fn:function(ref){
var item = ref.item;
return [(item.linkedCompanyDisplayId)?_c('div',[_vm._v(" "+_vm._s(item.linkedCompanyDisplayId)+" ")]):_c('div',[_vm._v(" "+_vm._s(_vm.$t('issues.notApplicable'))+" ")])]}},{key:"item.dateOfRegistration",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.dateOfRegistration,'DD.MM.YYYY hh:mm'))+" ")]}},{key:"item.issueState",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getState(item.issueState))+" ")]}},{key:"item.currentlyHandledBy",fn:function(ref){
var item = ref.item;
return [(item.currentlyHandledBy)?_c('div',[_vm._v(_vm._s(item.currentlyHandledBy.name)+" "+_vm._s(item.currentlyHandledBy.surname))]):_vm._e()]}},(_vm.$permission('IssueTakeover'))?{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.$router.push({
                  name: 'DetailsIssueCustomerService',
                  params: { id: item.id }
                })}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('issues.goToDetails'))+" ")])],1)],1)],1)]}}:null,{key:"expanded-item",fn:function(ref){
                var headers = ref.headers;
                var item = ref.item;
return [_c('td',{staticClass:"pa-6",attrs:{"colspan":headers.length}},[_c('h4',[_vm._v(_vm._s(_vm.$t('issues.issueState')))]),_c('v-data-table',{attrs:{"hide-default-footer":"","headers":_vm.detailsHeaders,"items":item.assignmentHistory},scopedSlots:_vm._u([{key:"item.department",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDepartment(item.department))+" ")]}},{key:"item.dateOfAssignment",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.dateOfAssignment,'DD.MM.YYYY hh:mm'))+" ")]}},{key:"item.assignedTo",fn:function(ref){
                var item = ref.item;
return [(item.assignedTo)?_c('div',[_vm._v(" "+_vm._s(item.assignedTo.name)+" "+_vm._s(item.assignedTo.surname)+" ")]):_vm._e()]}}],null,true)})],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
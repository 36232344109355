<template>
  <div>
    <data-table
      :headers="headers"
      hide-default-footer
      server-pagination
      :search="search"
      endpoint="Issue/GetGeneralIssueDrafts"
      :mappingFunction="mappingFunction"
    >
      <template v-slot:[`item.orderingCompanyUserId`]="{ item }">
        <div v-if="item.orderingCompanyUser">
          {{ item.orderingCompanyUser.name }}
          {{ item.orderingCompanyUser.surname }}
        </div>
      </template>
      <template v-slot:[`item.linkedCompanyUserId`]="{ item }">
        <div v-if="item.linkedCompanyUser">
          {{ item.linkedCompanyUser.name }} {{ item.linkedCompanyUser.surname }}
        </div>
      </template>
      <template v-slot:[`item.linkedCompanyDisplayId`]="{ item }">
        <div v-if="item.linkedCompanyDisplayId">
          {{ item.linkedCompanyDisplayId }}
        </div>
      </template>
      <template v-slot:[`item.dateOfRegistration`]="{ item }">
        {{ item.dateOfRegistration | dateFormat('DD.MM.YYYY hh:mm') }}
      </template>
      <template v-slot:[`item.createdBy`]="{ item }">
        <div v-if="item.createdBy">{{ item.createdBy.name }} {{ item.createdBy.surname }}</div>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-menu bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item link @click="
                  $router.push({
                    name: 'DetailsDraftIssueCustomerService',
                    params: { id: item.id }
                  })
                ">
              <v-list-item-title
                >{{ $t('issues.goToDetails') }}</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </data-table>
  </div>
</template>

<script>
export default {
  name: 'DraftIssuesTable',
  props: {
    users: {
      type: Array,
      required: true
    },
    search: String
  },
  data () {
    return {
      headers: [
        {
          text: this.$t('issues.orderingUserFullName'),
          value: 'orderingCompanyUserId',
          width: 150
        },
        {
          text: this.$t('issues.companyDisplayId'),
          value: 'orderingCompanyDisplayId'
        },
        {
          text: this.$t('issues.linkedCompanyUserFullName'),
          value: 'linkedCompanyUserId',
          width: 150
        },
        {
          text: this.$t('issues.companyDisplayId'),
          value: 'linkedCompanyDisplayId'
        },
        {
          text: this.$t('issues.category'),
          value: 'category'
        },
        {
          text: this.$t('issues.dateOfRegistration'),
          value: 'dateOfRegistration'
        },
        {
          text: this.$t('issues.createdBy'),
          value: 'createdBy'
        },
        {
          text: '',
          value: 'actions',
          sortable: false
        }
      ]
    }
  },
  methods: {
    getUser (userId) {
      return this.users.find((user) => user.id === userId)
    },
    mappingFunction (result) {
      return result.map((issue) => {
        const user = this.getUser(issue.createdBy)
        if (user) {
          issue = {
            ...issue,
            user
          }
        }

        const orderingCompanyUser = this.getUser(issue.orderingCompanyUserId)
        if (orderingCompanyUser) {
          issue = {
            ...issue,
            orderingCompanyUser
          }
        }

        const linkedCompanyUser = this.getUser(issue.linkedCompanyUserId)
        if (linkedCompanyUser) {
          issue = {
            ...issue,
            linkedCompanyUser
          }
        }

        return issue
      })
    }
  }
}
</script>

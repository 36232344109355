var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('data-table',{attrs:{"headers":_vm.headers,"hide-default-footer":"","server-pagination":"","search":_vm.search,"endpoint":"Issue/GetGeneralIssueDrafts","mappingFunction":_vm.mappingFunction},scopedSlots:_vm._u([{key:"item.orderingCompanyUserId",fn:function(ref){
var item = ref.item;
return [(item.orderingCompanyUser)?_c('div',[_vm._v(" "+_vm._s(item.orderingCompanyUser.name)+" "+_vm._s(item.orderingCompanyUser.surname)+" ")]):_vm._e()]}},{key:"item.linkedCompanyUserId",fn:function(ref){
var item = ref.item;
return [(item.linkedCompanyUser)?_c('div',[_vm._v(" "+_vm._s(item.linkedCompanyUser.name)+" "+_vm._s(item.linkedCompanyUser.surname)+" ")]):_vm._e()]}},{key:"item.linkedCompanyDisplayId",fn:function(ref){
var item = ref.item;
return [(item.linkedCompanyDisplayId)?_c('div',[_vm._v(" "+_vm._s(item.linkedCompanyDisplayId)+" ")]):_vm._e()]}},{key:"item.dateOfRegistration",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.dateOfRegistration,'DD.MM.YYYY hh:mm'))+" ")]}},{key:"item.createdBy",fn:function(ref){
var item = ref.item;
return [(item.createdBy)?_c('div',[_vm._v(_vm._s(item.createdBy.name)+" "+_vm._s(item.createdBy.surname))]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.$router.push({
                  name: 'DetailsDraftIssueCustomerService',
                  params: { id: item.id }
                })}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('issues.goToDetails')))])],1)],1)],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('data-table',{ref:"dataTable",attrs:{"headers":_vm.headers,"server-pagination":"","show-expand":"","single-expand":"","endpoint":"/Issue/GetWaitingGeneralIssues","mappingFunction":_vm.mappingFunction,"search":_vm.search},on:{"click:row":function($event){return _vm.$emit('click:row', $event)}},scopedSlots:_vm._u([{key:"item.orderingCompanyUserId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.orderingCompanyUser.name)+" "+_vm._s(item.orderingCompanyUser.surname)+" ")]}},{key:"item.linkedCompanyUserId",fn:function(ref){
var item = ref.item;
return [(item.linkedCompanyUser)?_c('div',[_vm._v(" "+_vm._s(item.linkedCompanyUser.name)+" "+_vm._s(item.linkedCompanyUser.surname)+" ")]):_c('div',[_vm._v(" "+_vm._s(_vm.$t('issues.notApplicable'))+" ")])]}},{key:"item.linkedCompanyDisplayId",fn:function(ref){
var item = ref.item;
return [(item.linkedCompanyDisplayId)?_c('div',[_vm._v(" "+_vm._s(item.linkedCompanyDisplayId)+" ")]):_c('div',[_vm._v(" "+_vm._s(_vm.$t('issues.notApplicable'))+" ")])]}},{key:"item.dateOfRegistration",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.dateOfRegistration,'DD.MM.YYYY hh:mm'))+" ")]}},{key:"item.issueState",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getState(item.issueState))+" ")]}},{key:"item.currentlyHandledBy",fn:function(ref){
var item = ref.item;
return [_c('div',[(_vm.usersToAssign.length > 1 && _vm.$permission('AssignIssueToUser'))?_c('v-select',{staticClass:"mt-n1",attrs:{"items":_vm.usersToAssign,"placeholder":_vm.$t('issues.assign'),"item-value":"id","item-text":"name","dense":"","hide-details":""},on:{"change":function($event){return _vm.selectUser(item.id, $event)}}}):(_vm.$permission('IssueTakeover'))?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.selectUser(item.id, _vm.$store.getters.userCommonData.id)}}},[_vm._v(" "+_vm._s(_vm.$t('issues.assignToMe'))+" ")]):_vm._e()],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"pa-6",attrs:{"colspan":headers.length}},[_c('h4',[_vm._v(_vm._s(_vm.$t('issues.issueState')))]),_c('v-data-table',{attrs:{"hide-default-footer":"","headers":_vm.detailsHeaders,"items":item.assignmentHistory},scopedSlots:_vm._u([{key:"item.department",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("getDescription")(item.department,_vm.departmentTypes))+" ")]}},{key:"item.dateOfAssignment",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.dateOfAssignment,'DD.MM.YYYY hh:mm'))+" ")]}},{key:"item.assignedTo",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.assignedTo.name)+" "+_vm._s(item.assignedTo.surname)+" ")]}}],null,true)})],1)]}},(_vm.$permission('IssueTakeover') && _vm.$permission('ViewAllIssues'))?{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.$router.push({
                  name: 'DetailsIssueCustomerService',
                  params: { id: item.id }
                })}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('issues.goToDetails')))])],1)],1)],1)]}}:null],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
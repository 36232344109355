<template>
  <div>
    <data-table
      :headers="headers"
      hide-default-footer
      show-expand
      single-expand
      server-pagination
      :search="search"
      endpoint="/Issue/GetCurrentGeneralIssues"
      :mappingFunction="mappingFunction"
    >
      <template v-slot:[`item.orderingCompanyUserId`]="{ item }">
        <div v-if="item.orderingCompanyUser">
          {{ item.orderingCompanyUser.name }}
          {{ item.orderingCompanyUser.surname }}
        </div>
      </template>
      <template v-slot:[`item.linkedCompanyUserId`]="{ item }">
        <div v-if="item.linkedCompanyUser">
          {{ item.linkedCompanyUser.name }} {{ item.linkedCompanyUser.surname }}
        </div>
        <div v-else>
          {{ $t('issues.notApplicable') }}
        </div>
      </template>
      <template v-slot:[`item.linkedCompanyDisplayId`]="{ item }">
        <div v-if="item.linkedCompanyDisplayId">
          {{ item.linkedCompanyDisplayId }}
        </div>
        <div v-else>
          {{ $t('issues.notApplicable') }}
        </div>
      </template>
      <template v-slot:[`item.dateOfRegistration`]="{ item }">
        {{ item.dateOfRegistration | dateFormat('DD.MM.YYYY hh:mm') }}
      </template>
      <template v-slot:[`item.issueState`]="{ item }">
        {{ getState(item.issueState) }}
      </template>
      <template v-slot:[`item.currentlyHandledBy`]="{ item }">
        <div v-if="item.currentlyHandledBy">{{ item.currentlyHandledBy.name }} {{ item.currentlyHandledBy.surname }}</div>
      </template>
      <template v-if="$permission('IssueTakeover')" v-slot:[`item.actions`]="{ item }">
        <v-menu bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item link @click="
                  $router.push({
                    name: 'DetailsIssueCustomerService',
                    params: { id: item.id }
                  })
                ">
              <v-list-item-title
                >{{ $t('issues.goToDetails') }}
                </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td class="pa-6" :colspan="headers.length">
          <h4>{{ $t('issues.issueState') }}</h4>
          <v-data-table
            hide-default-footer
            :headers="detailsHeaders"
            :items="item.assignmentHistory"
          >
            <template v-slot:[`item.department`]="{ item }">
              {{ getDepartment(item.department) }}
            </template>
            <template v-slot:[`item.dateOfAssignment`]="{ item }">
              {{ item.dateOfAssignment | dateFormat('DD.MM.YYYY hh:mm') }}
            </template>
            <template v-slot:[`item.assignedTo`]="{ item }">
              <div v-if="item.assignedTo">
                {{ item.assignedTo.name }} {{ item.assignedTo.surname }}
              </div>
            </template>
          </v-data-table>
        </td>
      </template>
    </data-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getEnumDescription } from '@/utils/helpers'

export default {
  name: 'CurrentIssuesTable',
  props: {
    users: {
      type: Array,
      required: true
    },
    search: String
  },
  data () {
    return {
      headers: [
        {
          text: this.$t('issues.orderingUserFullName'),
          value: 'orderingCompanyUserId',
          width: 150
        },
        {
          text: this.$t('issues.companyDisplayId'),
          value: 'orderingCompanyDisplayId'
        },
        {
          text: this.$t('issues.linkedCompanyUserFullName'),
          value: 'linkedCompanyUserId',
          width: 150
        },
        {
          text: this.$t('issues.companyDisplayId'),
          value: 'linkedCompanyDisplayId'
        },
        {
          text: this.$t('issues.issueId'),
          value: 'issueDisplayId'
        },
        {
          text: this.$t('issues.category'),
          value: 'category'
        },
        {
          text: this.$t('issues.dateOfRegistration'),
          value: 'dateOfRegistration'
        },
        {
          text: this.$t('issues.issueState'),
          value: 'issueState'
        },
        {
          text: this.$t('issues.assignedTo'),
          value: 'currentlyHandledBy'
        },
        {
          text: '',
          value: 'actions',
          sortable: false
        }
      ],
      detailsHeaders: [
        {
          text: this.$t('issues.department'),
          value: 'department',
          width: '15%'
        },
        {
          text: this.$t('issues.dateOfAssignment'),
          value: 'dateOfAssignment',
          width: '15%'
        },
        {
          text: this.$t('issues.assignedTo'),
          value: 'assignedTo',
          width: '20%'
        },
        {
          text: '',
          value: '',
          width: '55%',
          sortable: false
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      enums: 'enums'
    })
  },
  methods: {
    getState (value) {
      return getEnumDescription(this.enums.issueState, value)
    },
    getUser (userId) {
      return this.users.find((user) => user.id === userId)
    },
    getDepartment (value) {
      return getEnumDescription(this.enums.departmentTypes, value)
    },
    mappingFunction (result) {
      return result.map((issue) => {
        const user = this.getUser(issue.currentlyHandledBy)
        if (user) {
          issue = {
            ...issue,
            user
          }
        }

        const orderingCompanyUser = this.getUser(issue.orderingCompanyUserId)
        if (orderingCompanyUser) {
          issue = {
            ...issue,
            orderingCompanyUser
          }
        }

        const linkedCompanyUser = this.getUser(issue.linkedCompanyUserId)
        if (linkedCompanyUser) {
          issue = {
            ...issue,
            linkedCompanyUser
          }
        }

        issue.assignmentHistory = issue.assignmentHistory.map((historyItem) => {
          const historyItemUser = this.getUser(historyItem.assignedTo)
          if (historyItemUser) {
            return {
              ...historyItem,
              user: {
                ...historyItemUser
              }
            }
          } else {
            return historyItem
          }
        })

        return issue
      })
    }
  }
}
</script>
